/* GiraffeComponent.css */
body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    background-color: #444;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vmin;
    /* Use the smaller of viewport width or height */
    height: 100vmin;
    /* Use the smaller of viewport width or height */
}

.grid {
    display: grid;
    grid-template-columns: repeat(32, 1fr);
    grid-template-rows: repeat(32, 1fr);
    width: 100%;
    height: 100%;
}

.cell {
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: #999;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 300px;
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.row {
    display: contents;
    /* Allows the row to be a container without affecting the grid layout */
}

.modal-color-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10pt;
}